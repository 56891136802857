:root {
  --unanswered-color: #000;
  --unanswered-background-color: #d9534f;
  --unanswered-background-color-hover: #c9302c;

  --answered-color: #000;
  --answered-background-color: #5cb85c;
  --answered-background-color-hover: #449d44;

  --marked-color: #000;
  --marked-background-color: #f0ad4e;
  --marked-background-color-hover: #ec971f;
}

@mixin colored-button($color) {
  .btn-#{$color} {
    color: var(--#{$color}-color);
    background-color: var(--#{$color}-background-color);
    border-color: var(--#{$color}-background-color);

    &:hover:not(:disabled) {
      background-color: var(--#{$color}-background-color-hover);
      border-color: var(--#{$color}-background-color-hover);
    }
  }
}

@include colored-button('unanswered');

@include colored-button('answered');

@include colored-button('marked');
