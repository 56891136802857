/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

app-root .mat-select-value {
  max-width: unset;
}

.table {
  margin-bottom: 0;
}

.text-bold {
  font-weight: bold;
}

.fa-icon {
  width: 1.35rem;
  height: 1.35rem;
}

.fa-primary {
  fill: #5d6164ff;
}

.fa-secondary {
  fill: #5d616440;
}

.form-group {
  max-width: 25rem;

  label {
    margin-bottom: 0.25rem;
  }
}

.justify-self-end {
  justify-self: end;
}

@for $i from 1 through 10 {
  @if ($i > 0) {
    .is-width-#{$i} {
      width: #{$i/2}rem;
    }

    .is-height-#{$i} {
      height: #{$i/2}rem;
    }
  }
}

.content-grid>*:not(router-outlet):not(.content-grid-ignore) {
  display: grid;
  flex-grow: 1;
  width: 100%;
  grid-row-gap: 1.25rem;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "content";

  &.has-header {
    grid-template-areas:
      "header"
      "content";
  }

  .header {
    grid-area: header;
  }

  .content {
    grid-area: content;
    align-content: flex-start;
  }
}

.flex-auto-wrap {
  display: flex;
  flex-wrap: wrap;

  @for $i from 1 through 10 {
    $distance: #{$i / 8};

    &.gap-#{$i} {
      margin: -#{$distance}rem;

      >* {
        margin: #{$distance}rem;
      }
    }

    &.gap-x-#{$i} {
      margin-left: -#{$distance}rem;
      margin-right: -#{$distance}rem;

      >* {
        margin-left: #{$distance}rem;
        margin-right: #{$distance}rem;
      }
    }

    &.gap-y-#{$i} {
      margin-top: -#{$distance}rem;
      margin-bottom: -#{$distance}rem;

      >* {
        margin-top: #{$distance}rem;
        margin-bottom: #{$distance}rem;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.centered-content {
  display: flex;

  &.horizontal {
    justify-content: center;
  }

  &.vertical {
    justify-content: center;
  }
}

.mat-dialog-container {
  background-color: white;
}
