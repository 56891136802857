.mat-checkbox-layout {
  white-space: normal;
}

mat-menu {
  display: none;
}

.mat-menu-content > .mat-menu-item {
  height: unset;
  overflow: visible;
  white-space: normal;
  line-height: inherit;
  padding-top: 16px;
  padding-bottom: 16px;
}
